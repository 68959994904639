<template>
  <div class="markup-tables flex">
    <va-card class="flex mb-4" v-if="selectedFiles.length > 0">
      <va-card-title style="font-size: 1.5rem;">
        Telemetry View
      </va-card-title>
      <va-card-content>
        <div class="row">
          <va-card class="flex xs12 md4 offset--md1">
            <apexchart type="line" height="350" :options="videoChartOptions" :series="videoSeries" v-if="playChart"></apexchart>
            <apexchart type="line" height="350" :options="chartOptions" :series="series" @markerClick="markerClickHandler" v-else></apexchart>
            <va-button class="mr-2 mb-2" icon-right="fa4-stop" @click="playPauseChart(false)" v-if="playChart">
              Stop&nbsp;&nbsp;
            </va-button>
            <va-button class="mr-2 mb-2" icon-right="fa4-play" @click="playPauseChart(true)" v-else>
              Simulate&nbsp;&nbsp;
            </va-button>
          </va-card>
          <va-card class="flex xs12 md4 offset--md1">
            <va-card-content>
              <GMapMap style="width: 100%; height: 280px;" :center="center" :zoom="15" :options="options" ref="gMap">
                <!-- Remove other pointes -->
                <!--
                <GMapMarker
                  v-for="stat in markers"
                  :icon="'http://maps.google.com/mapfiles/ms/icons/red-dot.png'"
                  :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
                />
                -->
                <GMapMarker
                  v-for="stat in selectedItems"
                  :icon="'http://maps.google.com/mapfiles/ms/micons/man.png'"
                  :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
                />
                <GMapMarker
                  v-if="mapPoint"
                  :icon="'http://maps.google.com/mapfiles/ms/micons/man.png'"
                  :position="{ lat: parseFloat(mapPoint.lat), lng: parseFloat(mapPoint.lon) }"
                />
                <GMapMarker
                  v-if="lastPoint"
                  :icon="'http://maps.google.com/mapfiles/ms/icons/red-dot.png'"
                  :position="{ lat: lastPoint.lat, lng: lastPoint.lng }"
                />
                <GMapInfoWindow
                  v-for="stat in infoWindows"
                  :closeclick="true"
                  :opened="true"
                  :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
                  >
                  {{ stat.address }}
                </GMapInfoWindow>
                <GMapMarker v-for="stat in infoWindows"
                  :key="stat.uid"
                  :position="{ lat: parseFloat(stat.lat), lng: parseFloat(stat.lon) }"
                  @click="openMarker(stat.uid)"
                >
                  <GMapInfoWindow
                    :closeclick="true"
                    @closeclick="openMarker(null)"
                    :opened="openedMarkerID === stat.uid"
                    >
                    <div id="contet">
                      <div id="siteNotice"></div>
                      <h1 id="firstHeading" class="firstHeading">Address</h1>
                      <div id="bodyContent">
                        <p>
                          {{ stat.address }}
                        </p>
                      </div>
                    </div>
                  </GMapInfoWindow>
                </GMapMarker>
                <GMapPolyline :options="directionPath" :path="directionPath.path" v-if="directionPath" />
                <GMapPolyline :options="directionPathCourse" :path="directionPathCourse.path" v-if="directionPathCourse" />
                <GMapPolyline :options="flightPath" :path="flightPath.path" v-for="flightPath in flightPaths" />
              </GMapMap>
            </va-card-content>
          </va-card>
        </div>
      </va-card-content>
    </va-card>
    <va-card class="flex mb-4" v-if="selectedFiles.length > 0 && displayStat">
      <va-card-title :style="isMobile ? 'font-size: 1rem;' : 'font-size: 1.5rem;'">
        Selected File - &nbsp;
        <router-link :to="{ name: 'file', params: { id: $route.params.id, fileId: displayStat.uid }}">
          View in Detail
        </router-link>
      </va-card-title>
      <va-card-content>
        <div class="row">
          <va-data-table
            :items="displayStatData"
            :columns="statColumns"
            class="flex xs12 mb-6 offset--md1"
          />
        </div>
      </va-card-content>
    </va-card>
    <va-card class="flex mb-4" v-if="selectedFiles.length <= 0">
      <va-card-title :style="isMobile ? 'font-size: 1.25rem;' : 'font-size: 1.5rem;'">
        Files&nbsp;
        <span v-if="session">- {{ session.label }}</span>&nbsp;
        <va-icon :name="'fa4-edit'" class="link" style="padding-left: 1rem;" @click="editModal" />
        <va-badge
          overlap
          :text="attachments"
        >
          <va-icon :name="'fa4-paperclip'" class="link" style="padding-left: 1rem;" @click="showAttachments" />
        </va-badge>
      </va-card-title>
      <va-card-content v-if="session">
        <div class="row">
          <va-input
            class="flex mb-2 md6 xs9"
            placeholder="Filter..."
            v-model="filterText"
            v-on:keyup.enter="addFilter"
          />
          <div class="flex mb-2 md3 xs3 text-left" style="padding-right: 4rem;" v-if="filterText.length > 0">
            <va-icon :size="'2rem'" :name="'fa4-plus-square'" class="link" @click="addFilter" />
          </div>
          <div class="flex mb-2 md3 xs12" :class="isMobile ? 'text-center' : 'text-right'" :style="isMobile ? 'padding-right: 4rem;' : ''" v-if="filterText.length > 0 && selectedItems.length > 0">
            <va-icon :size="'1.8rem'" :name="'fa4-download'" class="download-icon" style="padding-top: 0.3rem; cursor: pointer;" @click="downloadMultipleFiles" />
            <va-icon :size="'2rem'" :name="'fa4-trash'" style="padding-left: 1rem; cursor: pointer; color: red;" @click="removeMultiModal" />
            <va-icon :size="'1.6rem'" :name="'material-icons-map'" class="link" style="padding-left: 1rem;" @click="visualizeSessions" v-if="session.mode === 'Telemetry Mode'" />
          </div>
          <div class="flex mb-2 md6 xs12" :class="isMobile ? 'text-center' : 'text-right'" :style="isMobile ? 'padding-right: 4rem;' : ''" v-else-if="filterText.length <= 0 && selectedItems.length > 0">
            <va-icon :size="'1.8rem'" :name="'fa4-download'" class="download-icon" style="padding-top: 0.3rem; cursor: pointer;" @click="downloadMultipleFiles" />
            <va-icon :size="'2rem'" :name="'fa4-trash'" style="padding-left: 1rem; cursor: pointer; color: red;" @click="removeMultiModal" />
            <va-icon :size="'1.6rem'" :name="'material-icons-map'" class="link" style="padding-left: 1rem;" @click="visualizeSessions" v-if="session.mode === 'Telemetry Mode'" />
          </div>
        </div>
        <div class="row">
          <div class="flex xs12">
            <va-chip outline class="mb-2 mr-2" color="primary" v-for="(filter, i) in filters" v-bind:key="`chip${i}`">
              {{ filter }}&nbsp;<va-icon :size="'1rem'" :name="'fa4-remove'" style="cursor: pointer;" @click="removeFilter(i)" />
            </va-chip>
          </div>
        </div>
        <va-data-table
          :items="filtered"
          :columns="columns"
          :per-page="perPage"
          :current-page="currentPage"
          :selectable="true"
          v-model="selectedItems"
        >
          <template #bodyAppend>
            <tr>
              <td colspan="5" class="table-example--pagination">
                <va-pagination
                  v-model="currentPage"
                  input
                  :pages="pages"
                />
              </td>
              <td colspan="5" class="table-example--pagination">
                <va-select
                  style="width: 8rem;"
                  v-model="perPage"
                  :label="'Per Page'"
                  :options="perPageOptions"
                  noClear
                />
              </td>
            </tr>
          </template>
          <template #cell(uid)="{ source: uid }">
            <router-link :key="uid" :to="{ name: 'file', params: { id, fileId: uid }}">
              <va-icon :name="'fa4-eye'"/>
            </router-link>
            <va-icon :name="'fa4-trash'" style="padding-left: 1rem; cursor: pointer; color: red;" @click="removeModal(uid, label)" />
          </template>
        </va-data-table>
        <va-alert class="mt-3" border="left" v-if="filters.length > 0">
          <span>
            Number of filtered items:
            <va-chip>{{ filteredCount }}</va-chip>
          </span>
        </va-alert>
      </va-card-content>
    </va-card>

    <va-modal
      v-model="removeModalShow"
      hide-default-actions
      overlay-opacity="0.2"
      size="large"
    >
      <template #header>
        <h2>Remove a file</h2>
      </template>
      <slot>
        <div>Do you want to remove {{ removeName }}?</div>
      </slot>
      <template #footer>
        <va-button class="mr-2 mb-2" color="gray" @click="removeModalShow = false">Cancel</va-button>
        <va-button class="mr-2 mb-2" color="danger" @click="removeModalConfirm">Delete</va-button>
      </template>
    </va-modal>

    <va-modal
      v-model="editModalShow"
      hide-default-actions
      overlay-opacity="0.2"
      size="large"
    >
      <template #header>
        <h2>Edit a Session</h2>
      </template>
      <slot>
        <va-input
          class="mb-3"
          v-model="session.label"
          type="text"
          label="Label"
          style="margin-top: 1rem;"
        />
      </slot>
      <template #footer>
        <va-button class="mr-2 mb-2" color="gray" @click="editModalShow = false">Cancel</va-button>
        <va-button class="mr-2 mb-2" color="primary" @click="editModalConfirm">Save</va-button>
      </template>
    </va-modal>

    <va-modal
      v-model="removeMultiModalShow"
      hide-default-actions
      overlay-opacity="0.2"
      size="large"
    >
      <template #header>
        <h2>Remove files</h2>
      </template>
      <slot>
        <div>Do you want to remove the following files?</div>
        <br />
        <p v-for="removeMultiName in removeMultiNames">{{ removeMultiName }}</p>
      </slot>
      <template #footer>
        <va-button class="mr-2 mb-2" color="gray" @click="removeMultiModalShow = false">Cancel</va-button>
        <va-button class="mr-2 mb-2" color="danger" @click="removeMultiModalConfirm">Delete All</va-button>
      </template>
    </va-modal>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import VueApexCharts from 'vue3-apexcharts'
import { isMobile } from 'mobile-device-detect'
import JSZip from 'jszip'
import { saveAs } from 'save-as'

export default {
  components: {
    'apexchart': VueApexCharts
  },
  mounted: async function () {
    this.loader = this.$loading.show()
    try {
      const headers = {
        Authorization: `Bearer ${this.token}`
      }
      // Load session
      const session = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}`, { headers })
      this.session = session.data.session

      // Load a list of files
      const files = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}/files?sort_by=-timestamp`, { headers })
      this.files = await Promise.all(files.data.files.map((file) => {
        file.created_at = moment(file.created_at).format('YYYY-MM-DD hh:mm a')
        file.timestamp = moment.utc(file.timestamp).format('YYYY-MM-DD hh:mm a')
        this.filesObject[file.uid] = file.name
        return file
      }))
      this.files.sort((a,b) => {
        return new Date(b.timestamp) - new Date(a.timestamp)
      })
      // Load attachments
      const attachments = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}/attachments`, { headers })
      this.attachments = attachments.data.attachments.length
    } catch (error) {
      this.$router.push({ name: 'login', query: { redirect: `/pages/sessions/${this.id}/files` } })
    }
    if (this.isMobile) {
      this.columns = [
        {
          key: 'uid',
          label: 'Action',
          headerTitle: 'Action'
        },
        {
          key: 'name',
          label: 'Files',
          headerTitle: 'Files',
          sortable: true
        },
        {
          key: 'type',
          label: 'Type',
          headerTitle: 'Type',
          sortable: true
        },
        {
          key: 'timestamp',
          label: 'Captured At',
          headerTitle: 'Captured At',
          sortable: true
        },
        {
          key: 'created_at',
          label: 'Created on',
          headerTitle: 'Created on',
          sortable: true
        }
      ]
    }
    this.loader.hide()
  },
  data () {
    return {
      isMobile,
      loader: null,
      token: this.$store.state.token,
      id: this.$route.params.id,
      session: null,
      files: [],
      filesObject: {},
      selectedItems: [],
      selectedFiles: [],
      columns: [
        {
          key: 'name',
          label: 'Files',
          headerTitle: 'Files',
          sortable: true
        },
        {
          key: 'type',
          label: 'Type',
          headerTitle: 'Type',
          sortable: true
        },
        {
          key: 'timestamp',
          label: 'Captured At',
          headerTitle: 'Captured At',
          sortable: true
        },
        {
          key: 'created_at',
          label: 'Created on',
          headerTitle: 'Created on',
          sortable: true
        },
        {
          key: 'uid',
          label: 'Action',
          headerTitle: 'Action'
        }
      ],
      filterText: '',
      filters: [],
      perPage: 10,
      perPageOptions: [5, 10, 25, 50, 100],
      currentPage: 1,
      attachments: 0,
      removeModalShow: false,
      removeId: null,
      removeName: null,
      removeMultiModalShow: false,
      removeMultiNames: [],
      editModalShow: false,
      infoWindows: [],
      markers: [],
      center: null,
      lastPoint: null,
      flightPaths: [],
      mapPoint: null,
      directionPath: null,
      directionPathCourse: null,
      google: null,
      series: [
        {
          name: 'MIC',
          data: []
        },
        {
          name: 'ACCELUSERX',
          data: []
        },
        {
          name: 'ACCELUSERY',
          data: []
        },
        {
          name: 'ACCELUSERZ',
          data: []
        },
        {
          name: 'GYROX',
          data: []
        },
        {
          name: 'GYROY',
          data: []
        },
        {
          name: 'GYROZ',
          data: []
        },
        {
          name: 'SPEED',
          data: []
        }
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: true
          },
          foreColor: '#6E8192'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          width: 1
        },
        xaxis: {
          categories: [],
          type: 'datetime'
        },
        colors: ['#0000FF', '#5F0F40', '#00B577', '#FF0025', '#4F399A', '#008DFB', '#006E48', '#888f01']
      },
      videoSeries: [
        {
          name: 'MIC',
          data: []
        },
        {
          name: 'ACCELUSERX',
          data: []
        },
        {
          name: 'ACCELUSERY',
          data: []
        },
        {
          name: 'ACCELUSERZ',
          data: []
        },
        {
          name: 'GYROX',
          data: []
        },
        {
          name: 'GYROY',
          data: []
        },
        {
          name: 'GYROZ',
          data: []
        },
        {
          name: 'SPEED',
          data: []
        }
      ],
      videoChartOptions: {
        chart: {
          height: 350,
          type: 'line',
          animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
              speed: 1000
            }
          },
          zoom: {
            enabled: true
          },
          foreColor: '#6E8192'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          width: 1
        },
        xaxis: {
          categories: [],
          type: 'datetime',
          range: 2000
        },
        yaxis: {},
        colors: ['#0000FF', '#5F0F40', '#00D68D', '#FF0025', '#4F399A', '#1500FB', '#006E48', '#888f01']
      },
      videoChart: false,
      playChart: false,
      playPauseChartTimeout: null,
      stats: [],
      displayStat: null,
      displayStatData: [],
      statColumns: [
        {
          key: 'type',
          label: 'Type',
          headerTitle: 'Type'
        },
        {
          key: 'lat',
          label: 'Lat',
          headerTitle: 'Lat'
        },
        {
          key: 'lon',
          label: 'Long',
          headerTitle: 'Long'
        },
        {
          key: 'accelUserX',
          label: 'accelUserX',
          headerTitle: 'accelUserX'
        },
        {
          key: 'accelUserY',
          label: 'accelUserY',
          headerTitle: 'accelUserY'
        },
        {
          key: 'accelUserZ',
          label: 'accelUserZ',
          headerTitle: 'accelUserZ'
        },
        {
          key: 'gyroX',
          label: 'gyroX',
          headerTitle: 'gyroX'
        },
        {
          key: 'gyroY',
          label: 'gyroY',
          headerTitle: 'gyroY'
        },
        {
          key: 'gyroZ',
          label: 'gyroZ',
          headerTitle: 'gyroZ'
        },
        {
          key: 'mic',
          label: 'Mic',
          headerTitle: 'Mic'
        },
        {
          key: 'speed',
          label: 'Speed',
          headerTitle: 'speed'
        }
      ],
      openedMarkerID: null,
      options: {
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        styles: [
          {
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#1d2c4d"
              }
            ]
          },
          {
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#8ec3b9"
              }
            ]
          },
          {
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#1a3646"
              }
            ]
          },
          {
            "featureType": "administrative.country",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#4b6878"
              }
            ]
          },
          {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#64779e"
              }
            ]
          },
          {
            "featureType": "administrative.province",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#4b6878"
              }
            ]
          },
          {
            "featureType": "landscape.man_made",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#334e87"
              }
            ]
          },
          {
            "featureType": "landscape.natural",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#023e58"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#283d6a"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#6f9ba5"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#1d2c4d"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "geometry.fill",
            "stylers": [
              {
                "color": "#023e58"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#3C7680"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#304a7d"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#98a5be"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#1d2c4d"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#2c6675"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#255763"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#b0d5ce"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#023e58"
              }
            ]
          },
          {
            "featureType": "transit",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#98a5be"
              }
            ]
          },
          {
            "featureType": "transit",
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#1d2c4d"
              }
            ]
          },
          {
            "featureType": "transit.line",
            "elementType": "geometry.fill",
            "stylers": [
              {
                "color": "#283d6a"
              }
            ]
          },
          {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#3a4762"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#0e1626"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#4e6d70"
              }
            ]
          }
        ]
      }
    }
  },
  computed: {
    pages () {
      return (this.perPage && this.perPage !== 0)
        ? Math.ceil(this.filtered.length / this.perPage)
        : this.filtered.length
    },
    filtered () {
      if (!this.filters || this.filters.length < 1) {
        return this.files
      }

      let tempFiltered = this.files
      this.filters.forEach((filter) => {
        if (filter.trim().length > 0) {
          tempFiltered = tempFiltered.filter(file => {
            return (file.name ? file.name.toLowerCase().includes(filter.trim().toLowerCase()) : false) ||
                   (file.type ? file.type.toLowerCase().includes(filter.trim().toLowerCase()) : false) ||
                   (file.created_at ? file.created_at.toLowerCase().includes(filter.trim().toLowerCase()) : false)
          })
        }
      })
      return tempFiltered
    },
    filteredCount () {
      return this.filtered.length
    }
  },
  methods: {
    removeModal (id) {
      this.removeId = id
      this.removeName = this.filesObject[id]
      this.removeModalShow = true
    },
    removeModalConfirm: async function () {
      this.loader = this.$loading.show()
      try {
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        // Delete a file
        await axios.delete(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}/files/${this.removeId}`, { headers })
        // Show success message
        this.$vaToast.init({
          message: 'File deleted successfully!',
          iconClass: 'fa-star-o',
          position: 'bottom-right',
          duration: 6000,
          fullWidth: false,
          color: 'primary',
        })

        // Load a list of file again
        const files = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}/files?sort_by=-timestamp`, { headers })
        this.files = await Promise.all(files.data.files.map((file) => {
          file.created_at = moment(file.created_at).format('YYYY-MM-DD hh:mm a')
          return file
        }))

        this.removeId = null
        this.removeName = null
        this.removeModalShow = false
      } catch (error) {
        this.removeId = null
        this.removeName = null
        this.removeModalShow = false
        this.$router.push({ name: 'login', query: { redirect: `/pages/sessions/${this.id}/files` } })
      }
      this.loader.hide()
    },
    removeMultiModal: async function () {
      this.removeMultiNames = await Promise.all(this.selectedItems.map((selectedItem) => selectedItem.name))
      this.removeMultiModalShow = true
    },
    removeMultiModalConfirm: async function () {
      this.loader = this.$loading.show()
      try {
        // Delete sessions
        await Promise.all(this.selectedItems.map((selectedItem) => this.removeItem(selectedItem.uid)))
        this.$vaToast.init({
          message: 'Files deleted successfully!',
          iconClass: 'fa-star-o',
          position: 'bottom-right',
          duration: 6000,
          fullWidth: false,
          color: 'primary',
        })

        // Load a list of file again
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        const files = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}/files?sort_by=-timestamp`, { headers })
        this.files = await Promise.all(files.data.files.map((file) => {
          file.created_at = moment(file.created_at).format('YYYY-MM-DD hh:mm a')
          return file
        }))

        // Load a list of session again
        this.selectedItems = []
        this.removeMultiNames = []
        this.removeMultiModalShow = false
      } catch (error) {
        this.selectedItems = []
        this.removeMultiNames = []
        this.removeMultiModalShow = false
        this.$router.push({ name: 'login', query: { redirect: `/pages/sessions/${this.id}/files` } })
      }
      this.loader.hide()
    },
    removeItem (id) {
      const headers = {
        Authorization: `Bearer ${this.token}`
      }
      return axios.delete(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}/files/${id}`, { headers })
    },
    editModal () {
      this.editModalShow = true
    },
    editModalConfirm: async function () {
      this.loader = this.$loading.show()
      try {
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        const payload = {
          label: this.session.label
        }
        // Edit a session
        await axios.put(`${process.env.VUE_APP_API_URL}/api/sessions/${this.session.uid}`, payload, { headers })
        // Show success message
        this.$vaToast.init({
          message: 'Session edited successfully!',
          iconClass: 'fa-star-o',
          position: 'bottom-right',
          duration: 6000,
          fullWidth: false,
          color: 'primary',
        })

        // Load session again
        const session = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${this.session.uid}`, { headers })
        this.session = session.data.session

        this.editModalShow = false
      } catch (error) {
        this.editModalShow = false
        this.$router.push({ name: 'login', query: { redirect: `/pages/sessions/${this.id}/files` } })
      }
      this.loader.hide()
    },
    downloadMultipleFiles: async function () {
      let self = this
      const zip = new JSZip()
      const files = zip.folder(self.session.label)
      this.loader = this.$loading.show()
      try {
        // Download CSVs
        this.$vaToast.init({
          message: 'Preparing CSV files!',
          iconClass: 'fa-star-o',
          position: 'bottom-right',
          duration: 6000,
          fullWidth: false,
          color: 'primary',
        })

        const headers = {
          Authorization: `Bearer ${this.token}`
        }

        await Promise.all(this.selectedItems.map(async (selectedItem) => {

          const rows = [
            ['lat', 'lon', 'accelUserX', 'accelUserY', 'accelUserZ', 'gyroX', 'gyroY', 'gyroZ', 'pitch', 'heading', 'speed', 'course', 'mic', 'pressure', 'calMagX', 'calMagY', 'calMagZ', 'calMagTotal', 'timestamp']
          ]
          const file = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}/files/${selectedItem.uid}`, { headers })
          file.data.file.stats.forEach((stat) => {
            rows.push([
              stat.lat, stat.lon, stat.accelUserX, stat.accelUserY, stat.accelUserZ, stat.gyroX, stat.gyroY, stat.gyroZ, stat.pitch, stat.heading, stat.speed, stat.course, stat.mic, stat.pressure, stat.calMagX, stat.calMagY, stat.calMagZ, stat.calMagTotal, stat.timestamp
            ])
          })
          const csvContent = rows.map(e => e.join(',')).join('\n')

          const csvBlob = new Blob([csvContent], {
            type: 'text/csv'
          })
          const encodedUri = encodeURI(csvContent)
          files.file(`${file.data.file.name.split('.')[0]}.csv`, csvBlob)

        }))

        zip.generateAsync({type:'blob'}).then(function(content) {
            saveAs(content, `${self.session.label}.zip`);
        })

        this.$vaToast.init({
          message: 'Downloading CSV files!',
          iconClass: 'fa-star-o',
          position: 'bottom-right',
          duration: 6000,
          fullWidth: false,
          color: 'primary',
        })

        // Reset everything
        this.selectedItems = []
        this.removeMultiNames = []
        this.removeMultiModalShow = false
      } catch (error) {
        this.selectedItems = []
        this.removeMultiNames = []
        this.removeMultiModalShow = false
        this.$router.push({ name: 'login', query: { redirect: `/pages/sessions/${this.id}/files` } })
      }
      this.loader.hide()
    },
    addFilter: function () {
      if (this.filterText && this.filterText.length > 0) {
        this.filters.push(this.filterText.trim())
        this.filterText = ''
      }
    },
    removeFilter: function (index) {
      this.filters.splice(index, 1)
    },
    visualizeSessions: async function () {
      this.loader = this.$loading.show()

      try {
        const ids = await Promise.all(this.selectedItems.map((selectedItem) => selectedItem.uid))
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        const files = await axios.post(`${process.env.VUE_APP_API_URL}/api/sessions/${this.id}/files-ids`, { ids }, { headers })
        this.selectedFiles = files.data.files

        files.data.files.forEach((file) => {
          this.stats.push(Object.assign(file.first_location, { parent_id: file.uid }))
          this.stats.push(Object.assign(file.last_location, { parent_id: file.uid }))
          this.stats.push(Object.assign(file.min_mic, { parent_id: file.uid }))
          this.stats.push(Object.assign(file.max_mic, { parent_id: file.uid }))
          this.stats.push(Object.assign(file.min_acceluserx, { parent_id: file.uid }))
          this.stats.push(Object.assign(file.max_acceluserx, { parent_id: file.uid }))
          this.stats.push(Object.assign(file.min_accelusery, { parent_id: file.uid }))
          this.stats.push(Object.assign(file.max_accelusery, { parent_id: file.uid }))
          this.stats.push(Object.assign(file.min_acceluserz, { parent_id: file.uid }))
          this.stats.push(Object.assign(file.max_acceluserz, { parent_id: file.uid }))
          this.stats.push(Object.assign(file.min_gyrox, { parent_id: file.uid }))
          this.stats.push(Object.assign(file.max_gyrox, { parent_id: file.uid }))
          this.stats.push(Object.assign(file.min_gyroy, { parent_id: file.uid }))
          this.stats.push(Object.assign(file.max_gyroy, { parent_id: file.uid }))
          this.stats.push(Object.assign(file.min_gyroz, { parent_id: file.uid }))
          this.stats.push(Object.assign(file.max_gyroz, { parent_id: file.uid }))
          this.stats.push(Object.assign(file.min_speed, { parent_id: file.uid }))
          this.stats.push(Object.assign(file.max_speed, { parent_id: file.uid }))
        })
        this.stats = this.stats.sort((a,b) => {
          return new Date(a.timestamp) - new Date(b.timestamp)
        })
        this.center = {
          lat: this.stats[0] ? parseFloat(this.stats[0].lat) : 0.000000,
          lng: this.stats[0] ? parseFloat(this.stats[0].lon) : 0.000000
        }
        this.lastPoint = {
          lat: parseFloat(this.stats[this.stats.length - 1].lat),
          lng: parseFloat(this.stats[this.stats.length - 1].lon)
        }

        this.markers = []
        this.infoWindows = []
        const flightPlanCoordinates = []
        const flightPlanCoordinatesTracker = []

        this.stats.forEach((stat) => {
          // Fill graph data
          this.series[0].data.push(stat.mic)
          this.series[1].data.push(stat.accelUserX)
          this.series[2].data.push(stat.accelUserY)
          this.series[3].data.push(stat.accelUserZ)
          this.series[4].data.push(stat.gyroX)
          this.series[5].data.push(stat.gyroY)
          this.series[6].data.push(stat.gyroZ)
          this.series[7].data.push(stat.speed)
          this.chartOptions.xaxis.categories.push(stat.timestamp)
        })

        this.stats.forEach((stat) => {
          const newCoordinate = {
            lat: parseFloat(stat.lat),
            lng: parseFloat(stat.lon),
            timestamp: stat.timestamp
          }
          const newCoordinateTracker = `${stat.lat},${stat.lon}`
          if (flightPlanCoordinatesTracker.indexOf(newCoordinateTracker) < 0) {
            flightPlanCoordinates.push(newCoordinate)
            flightPlanCoordinatesTracker.push(newCoordinateTracker)
          }
          if (stat.address && this.infoWindows.length <= 0) {
            this.infoWindows.push(stat)
          } else {
            this.markers.push(stat)
          }
        })

        // Find min and max values
        const minMax = []
        minMax.push(Math.min(...this.series[0].data))
        minMax.push(Math.max(...this.series[0].data))
        minMax.push(Math.min(...this.series[1].data))
        minMax.push(Math.max(...this.series[1].data))
        minMax.push(Math.min(...this.series[2].data))
        minMax.push(Math.max(...this.series[2].data))
        minMax.push(Math.min(...this.series[3].data))
        minMax.push(Math.max(...this.series[3].data))
        minMax.push(Math.min(...this.series[4].data))
        minMax.push(Math.max(...this.series[4].data))
        minMax.push(Math.min(...this.series[5].data))
        minMax.push(Math.max(...this.series[5].data))
        minMax.push(Math.min(...this.series[6].data))
        minMax.push(Math.max(...this.series[6].data))
        minMax.push(Math.min(...this.series[7].data))
        minMax.push(Math.max(...this.series[7].data))

        this.videoChartOptions.yaxis.min = Math.min(...minMax)
        this.videoChartOptions.yaxis.max = Math.max(...minMax)

        if (this.infoWindows.length > 0) {
          const tempMarkers = []
          this.markers.forEach((marker) => {
            if (marker.lat !== this.infoWindows[0].lat || marker.lon !== this.infoWindows[0].lon) {
              tempMarkers.push(marker)
            }
          })
          this.markers = tempMarkers
        }

        let index = 0
        this.flightPaths = []
        let lastTimestamp = null
        flightPlanCoordinates.forEach((coordinate, i) => {
          if (i === 0) {
            this.flightPaths.push({
              path: [{
                lat: coordinate.lat,
                lng: coordinate.lng,
              }],
              geodesic: true,
              strokeColor: '#00FF00',
              strokeOpacity: 1.0,
              strokeWeight: 2,
            })
          } else if (((new Date(coordinate.timestamp).getTime() - new Date(lastTimestamp).getTime()) / 1000) > 120) {
            index = index + 1
            this.flightPaths.push({
              path: [{
                lat: coordinate.lat,
                lng: coordinate.lng,
              }],
              geodesic: true,
              strokeColor: '#00FF00',
              strokeOpacity: 1.0,
              strokeWeight: 2,
            })
          } else {
            this.flightPaths[index].path.push({
              lat: coordinate.lat,
              lng: coordinate.lng,
            })
          }
          lastTimestamp = coordinate.timestamp
        })

        let self = this
        setTimeout(() => {
          self.$refs.gMap.$mapPromise.then(() => {
            self.google = window.google
          })
        }, 1000)
      } catch (error) {
        this.$router.push({ name: 'login', query: { redirect: `/pages/sessions/${this.id}/files` } })
      }
      this.loader.hide()
    },
    markerClickHandler (event, chartContext, { seriesIndex, dataPointIndex, config }) {
      // Set Map point
      this.mapPoint = this.stats[dataPointIndex]
      const pointA = new this.google.maps.LatLng(parseFloat(this.stats[dataPointIndex].lat), parseFloat(this.stats[dataPointIndex].lon))
      const distance = 20 // in meters
      let bearing = parseFloat(this.stats[dataPointIndex].heading)
      let pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

      const lineSymbol = {
        path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      }

      this.directionPath = {
        path: [
          {
            lat: pointA.lat(),
            lng: pointA.lng()
          },
          {
            lat: pointB.lat(),
            lng: pointB.lng()
          }
        ],
        icons: [
          {
            icon: lineSymbol,
            offset: '100%',
          },
        ],
        geodesic: true,
        strokeColor: '#FA86C4',
        strokeOpacity: 1.0,
        strokeWeight: 2,
      }

      bearing = parseFloat(this.stats[dataPointIndex].course)
      pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

      this.directionPathCourse = {
        path: [
          {
            lat: pointA.lat(),
            lng: pointA.lng()
          },
          {
            lat: pointB.lat(),
            lng: pointB.lng()
          }
        ],
        icons: [
          {
            icon: lineSymbol,
            offset: '100%',
          },
        ],
        geodesic: true,
        strokeColor: '#006721',
        strokeOpacity: 1.0,
        strokeWeight: 2,
      }

      for (let i = 0; i < this.selectedFiles.length; i++) {
        if (this.selectedFiles[i].uid === this.stats[dataPointIndex].parent_id) {
          this.displayStat = this.selectedFiles[i]
          break
        }
      }

      // Display Stats
      this.displayStatData = [
        {
          type: 'MIN',
          lat: this.displayStat.first_location.lat,
          lon: this.displayStat.first_location.lon,
          mic: this.displayStat.min_mic.mic,
          accelUserX: this.displayStat.min_acceluserx.accelUserX,
          accelUserY: this.displayStat.min_accelusery.accelUserY,
          accelUserZ: this.displayStat.min_acceluserz.accelUserZ,
          gyroX: this.displayStat.min_gyrox.gyroX,
          gyroY: this.displayStat.min_gyroy.gyroY,
          gyroZ: this.displayStat.min_gyroz.gyroZ,
          speed: this.displayStat.min_speed.speed
        },
        {
          type: 'MAX',
          lat: this.displayStat.last_location.lat,
          lon: this.displayStat.last_location.lon,
          mic: this.displayStat.max_mic.mic,
          accelUserX: this.displayStat.max_acceluserx.accelUserX,
          accelUserY: this.displayStat.max_accelusery.accelUserY,
          accelUserZ: this.displayStat.max_acceluserz.accelUserZ,
          gyroX: this.displayStat.max_gyrox.gyroX,
          gyroY: this.displayStat.max_gyroy.gyroY,
          gyroZ: this.displayStat.max_gyroz.gyroZ,
          speed: this.displayStat.max_speed.speed
        }
      ]
    },
    playPauseChart (play) {
      if (play) {
        this.playChart = true

        let i = 0

        this.playPauseChartTimeout = setInterval(() => {
          if (!this.mapPoint || this.mapPoint.lat !== this.stats[i].lat || this.mapPoint.lon !== this.stats[i].lon) {
            this.mapPoint = this.stats[i]
            const pointA = new this.google.maps.LatLng(parseFloat(this.stats[i].lat), parseFloat(this.stats[i].lon))
            const distance = 20 // in meters
            let bearing = parseFloat(this.stats[i].heading)
            let pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

            const lineSymbol = {
              path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            }

            this.directionPath = {
              path: [
                {
                  lat: pointA.lat(),
                  lng: pointA.lng()
                },
                {
                  lat: pointB.lat(),
                  lng: pointB.lng()
                }
              ],
              icons: [
                {
                  icon: lineSymbol,
                  offset: '100%',
                },
              ],
              geodesic: true,
              strokeColor: '#FA86C4',
              strokeOpacity: 1.0,
              strokeWeight: 2,
            }

            bearing = parseFloat(this.stats[i].course)
            pointB = this.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)

            this.directionPathCourse = {
              path: [
                {
                  lat: pointA.lat(),
                  lng: pointA.lng()
                },
                {
                  lat: pointB.lat(),
                  lng: pointB.lng()
                }
              ],
              icons: [
                {
                  icon: lineSymbol,
                  offset: '100%',
                },
              ],
              geodesic: true,
              strokeColor: '#006721',
              strokeOpacity: 1.0,
              strokeWeight: 2,
            }
          }

          this.videoSeries[0].data.push(this.stats[i].mic)
          this.videoSeries[1].data.push(this.stats[i].accelUserX)
          this.videoSeries[2].data.push(this.stats[i].accelUserY)
          this.videoSeries[3].data.push(this.stats[i].accelUserZ)
          this.videoSeries[4].data.push(this.stats[i].gyroX)
          this.videoSeries[5].data.push(this.stats[i].gyroY)
          this.videoSeries[6].data.push(this.stats[i].gyroZ)
          this.videoSeries[7].data.push(this.stats[i].speed)
          this.videoChartOptions.xaxis.categories.push(this.stats[i].timestamp)

          i = i + 1

          if (i >= this.stats.length) {
            if (this.playPauseChartTimeout) {
              clearInterval(this.playPauseChartTimeout)
              this.playChart = false

              this.videoSeries[0].data = []
              this.videoSeries[1].data = []
              this.videoSeries[2].data = []
              this.videoSeries[3].data = []
              this.videoSeries[4].data = []
              this.videoSeries[5].data = []
              this.videoSeries[6].data = []
              this.videoSeries[7].data = []
              this.videoChartOptions.xaxis.categories = []
            }
          }
        }, 100)

      } else {
        if (this.playPauseChartTimeout) {
          clearInterval(this.playPauseChartTimeout)
        }

        this.playChart = false

        this.videoSeries[0].data = []
        this.videoSeries[1].data = []
        this.videoSeries[2].data = []
        this.videoSeries[3].data = []
        this.videoSeries[4].data = []
        this.videoSeries[5].data = []
        this.videoSeries[6].data = []
        this.videoSeries[7].data = []
        this.videoChartOptions.xaxis.categories = []
      }
    },
    openMarker(id) {
      this.openedMarkerID = id
    },
    showAttachments() {
      this.$router.push({ name: 'attachments', params: { id: this.id } })
    }
  },
}
</script>

<style lang="scss">
  .markup-tables {
    .table-wrapper {
      overflow: auto;
    }

    .va-table {
      width: 100%;
    }
  }

  .table-example--pagination {
    text-align: right;
    text-align: -webkit-right;
    padding-top: 1rem;
  }

  .link {
     cursor: pointer;
     color: var(--va-primary);
  }

  .link:hover {
     text-decoration: none;
  }

  .va-data-table {
    --va-data-table-thead-color: var(--va-primary);
  }

  .va-pagination__input {
    background-color: var(--va-white);
  }

  .va-alert {
    background-color: var(--va-white) !important;;
  }

  .vue-map > div > div > div > div > div > div > div > div > div > div > div {
    color: var(--va-pure-dark) !important;
  }
</style>
